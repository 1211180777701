






















































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import { getDicts, getDictName } from '@/utils/dict'

@Component({
  components: {
    Table,
    Pagination
  }
})
export default class VisitorList extends Vue {
  $constants: any
  // 字典数据
  // 信息发布类型
  newsTypeDicts: any = []
  noticeType = ''
  listLoading = false
  tableHeader = [
    {
      key: 'publishTime',
      name: '发布时间',
      width: '16%'
    },
    {
      key: 'title',
      name: '信息标题',
      width: '18%'
    },
    {
      key: 'content',
      name: '信息内容',
      width: '28%',
      isSpecial: true
    },
    {
      key: 'noticeType',
      name: '信息类型',
      width: '12%',
      isSpecial: true
    },
    {
      key: 'pushNumber',
      name: '推送人数',
      width: '10%'
    },
    {
      key: 'readNumber',
      name: '已读人数',
      width: '10%'
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }

  async beforeCreate() {
    // 获取相关字典
    // 信息发布类型
    this.newsTypeDicts = await getDicts('NOTICE_TYPE')
  }

  created() {
    this.getNewsList()
  }

  // 获取历史信息列表
  getNewsList() {
    this.listLoading = true
    this.$api.property
      .getHistoryNoticeList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        noticeType: this.noticeType === '' ? '' : Number(this.noticeType)
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        this.tableData = data.data
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  change() {
    this.listQuery.page = 1
    this.listQuery.limit = 15
    this.getNewsList()
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  go2Detail(item: any) {
    this.$router.push({
      path: '/property/news-history-detail',
      query: {
        id: item.id
      }
    })
  }
}
